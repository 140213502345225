import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import GeneralHeader from '../components/Header/GeneralHeader'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import Faq from '../components/shared/FAQ/Faq'
import FaqWhite from '../components/shared/FAQ/FaqWhite'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import ImageAndTextColumns from '../components/ImageAndTextColumns/ImageAndTextColumns'
import img from '../images/industry-sector/team_industry.svg'
import IndustryGrid from '../components/IndustrySector/IndustryGrid'
import icon1 from '../images/tax-credit/aerospace.svg'
import icon2 from '../images/tax-credit/cctv.svg'
import icon3 from '../images/tax-credit/paint.svg'
import icon4 from '../images/tax-credit/construction.svg'
import icon5 from '../images/tax-credit/electronics.svg'
import icon6 from '../images/tax-credit/engineering.svg'
import icon7 from '../images/tax-credit/materials.svg'
import icon8 from '../images/tax-credit/projects.svg'
import icon9 from '../images/tax-credit/sustainablity.svg'
import icon10 from '../images/tax-credit/pharmaceutical.svg'
import icon11 from '../images/tax-credit/testing.svg'
import { qs } from './claims-process'
import TextCols from '../components/TextCols/TextCols'
import rda from '../images/about-us/the_rda_way.svg'
import TwoCardSection from '../components/TwoCards/TwoCards'

export const qs6: Array<IQuestions> = [
  {
    question: 'What is IP?',
    answer: (
      <>
        <p>IP refers to the legal rights that protect creations of the mind, such as inventions, designs, brand names and artistic works. These rights allow individuals and businesses to control and benefit from their innovations, ensuring they are protected from unauthorised use by others.</p>
      </>
    ),
  },
]

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Intellectual Property',
    url: '/intellectual-property',
  },
]


const IndustrySector = () => {
  return (
    <Layout
      activeLink="/intellectual-property"
      title="R&D Intellectual Property Services"
      description="R&D Intellectual Property Services"
    >
      <GeneralHeader
        breadcrumbData={breadcrumbData}
        heading="Intellectual Property"
      />

      <ImageAndTextColumns
        tag="Intellectual Property"
        heading="Our Intellectual Property Services"
        body={[
          'RDA not only helps your business achieve valuable tax relief for the R&D you develop, but we also help you protect it. Read on to find out more about Intellectual Property (IP) in general and our dedicated, specialist IP-protection services.',
          <p key="q-ip" className="p-large">
      <strong>Whenever you conduct R&D, you are creating IP.</strong>
    </p>,
      <FaqWhite questions={qs6} />,
  ]}
        img={img}
        imgAlt="People in a meeting"
      />

      <TextCols
        heading=""
        leftCol={
          <div>
      <h3 style={{ fontSize: '2.5rem' }}>Types of IP</h3>
      <ul style={{ paddingTop: '20px' }}>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>Patents</strong>
            <span>
              – These protect inventions and technical innovations, giving the patent
              owner exclusive rights to manufacture, use, and sell the invention for a set
              period (typically 20 years in the UK).
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>Trademarks</strong>
            <span>
              – A form of protection for brand names, logos, slogans, and other identifiers,
              trademarks distinguish a business’s goods or services.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>Copyright</strong>
            <span>
              – For original literary, artistic, musical, and software works, a copyright
              will prevent unauthorised reproduction or distribution.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>Design Rights</strong>
            <span>
              – To protect the visual appearance, shape, or configuration of a product,
              a company can claim its design rights.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>Trade Secrets &amp; Confidential Information</strong>
            <span>
              – Where there is business‑sensitive information, such as formulas,
              algorithms, and manufacturing processes, trade secrets and
              confidential information apply.
            </span>
          </div>
        </li>
      </ul>
    </div>
        }
        rightCol={

           <div>
      <h3 style={{ fontSize: '2.5rem' }}>Why is IP Important?</h3>
      <ul style={{ paddingTop: '20px' }}>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>It encourages Innovation</strong>
            <span>
              – Protecting IP rights incentivises businesses and individuals to invest in research and development.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>It brings Competitive Advantage</strong>
            <span>
              – By ensuring exclusivity in the market, competitors can be prevented from copying valuable assets.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>Revenue Generation</strong>
            <span>
              – IP can be licensed, sold, or used as an asset to attract investment.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>Business Value</strong>
            <span>
              – The value of your IP can be added to the company’s balance sheet.
            </span>
          </div>
        </li>
        <li style={{ paddingBottom: '15px' }}>
          <div style={{ flexDirection: 'column' }}>
            <strong>Brand Protection</strong>
            <span>
              – Brand reputation can be defended, while also preventing counterfeit products from damaging business credibility.
            </span>
          </div>
        </li>
      </ul>
    </div>
        }
      />
      <ImageAndTextColumns
        flip
        tag=""
        heading="How to protect your IP"
        body={[
  <div key="ip-summary">
    <p>Understanding and managing your IP is crucial for business growth and security.</p>
    <p>Depending on the type of IP, protection can be achieved through:</p>
    <ul style={{ paddingTop: '15px' }}>
      <li>Filing patents, trademarks, or design registrations.</li>
      <li>Applying copyright automatically for creative works.</li>
      <li>Keeping trade secrets confidential through agreements and policies.</li>
    </ul>
    <p style={{ paddingTop: '15px' }}>If you need help identifying, protecting and leveraging your IP for strategic and commercial gain, contact our experts today to ensure your valuable assets are secure.</p>
  </div>,
]}

        img={rda}
        imgAlt="People in a meeting"
      />

       <TwoCardSection
        leftCard={{
          heading: 'Our IP Services',
          content: [
            'Find out more about RDA\'s latest Intellectual Property services',
            ],
          list: ['Invention Harvesting', 'Patent Landscaping', 'Freedom to Operate'],
          ctaUrl: '/intellectual-property-services',
          ctaText: 'Find Out More',
        }}
        rightCard={{
          heading: 'Patent Box',
          content: [
            'Help your business maximise the financial benefits of your IP, with Patent Box. A tax-incentive scheme introduced by the UK Government and overseen by HMRC.',
          ],
          ctaUrl: '/patent-box',
          ctaText: 'Find Out More',
        }}
      />

      <CallBackBanner />
    </Layout>
  )
}

export default IndustrySector
